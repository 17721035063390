<template>
    <div id="allConfig">
        <br>
        <el-form ref="form" :model="config" label-width="250px">
           <el-form-item label="积分名称设置" >
                <el-input size="small" v-model="config.score_name"  style="width:150px;"></el-input>
                <span style="color: red">*可以把积分设置成其他名称</span>
            </el-form-item>
            <el-form-item label="移动端皮肤设置" >
                <div class="colorSet" style="display: flex;">
                  <div @click="selectColorIndex(1)" :class="config.colorIndex==1?'active':''" style="background-color: rgb(38,162,255);"></div>
                  <div @click="selectColorIndex(2)" :class="config.colorIndex==2?'active':''" style="background-color: #67C23A;"></div>
                  <div @click="selectColorIndex(3)" :class="config.colorIndex==3?'active':''" style="background-color: #E6A23C;"></div>
                  <div @click="selectColorIndex(4)" :class="config.colorIndex==4?'active':''" style="background-color: #F56C6C;"></div>
                </div>
            </el-form-item>
            <el-form-item label="门店位置设置" >
                <el-input type="text" size="small" v-model="config.shop_address"  style="width:300px;"></el-input>
            </el-form-item>
            <el-form-item label="配送地点" >
                <el-input type="text" size="small" v-model="config.delivery_address"  style="width:300px;"></el-input>
            </el-form-item>
			<el-form-item label="下单是否赠送积分">
			   <el-radio-group v-model="config.is_score_give">
			     <el-radio label="0">不赠送</el-radio>
			     <el-radio label="1">赠送</el-radio>
			   </el-radio-group>
			 </el-form-item>
			 <el-form-item label="积分赠送占比" v-if="config.is_score_give==1">
			     <el-input-number :min="0" size="small" 
				 v-model="config.score_rebate"  
				 ></el-input-number> %
			 </el-form-item>
            <el-form-item label="展铺公告" >
                <el-input type="textarea" size="small" v-model="config.notice"  style="width:300px;"></el-input>
            </el-form-item>
            <!-- <el-form-item label="技术支持" >
                <el-input type="text" size="small" v-model="config.support"  style="width:300px;"></el-input>
            </el-form-item> -->
             <el-form-item label="会员是否可以下单(只针对团购商品)">
                <el-radio-group v-model="config.is_order">
                  <el-radio label="1">可以</el-radio>
                  <el-radio label="2">不可以</el-radio>
                  <el-radio label="3">指定地区</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="config.is_order==3" label="可下单地区">
                  <el-select v-model="selectAddress" multiple placeholder="请选择地区" size="small" style="width:400px;">
                      <el-option
                        v-for="item in addressList"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
              </el-form-item>
			  <el-form-item label="会员最多未完成订单数">
			     <el-input type="number" style="width:100px;" v-model="config.order_number"></el-input>
				 单
			   </el-form-item>
            <!-- <el-form-item label="收款码设置" >
                  <selecrImg @getImg="getImg"></selecrImg>
                   <img style="vertical-align: top;margin-top: 10px;" v-if="config.qr_img" @click="config.qr_img=''" width="150px" :src="config.qr_img" alt="">
            </el-form-item> -->
            <el-form-item label="门店logo" >
                  <selecrImg @getImg="getLogo"></selecrImg>
                   <div  class="close_img_box">
                   	<img v-if="config.shop_logo"  :src="goodsImgUrl + config.shop_logo" alt="" style="margin-right: 10px;width: 100px;height: 100px;margin-top: 10px;">
                   	<div class="close_img" @click="config.shop_logo=''" style="left: 70px;top: 10px;cursor: pointer;">
                   	  <img src="@/assets/img/close_white.svg" style="right: 1px;">
                   	</div>
                   </div>
            </el-form-item>
            <!-- <el-form-item label="appId" v-if="false">
                <el-input size="small" v-model="config.appid"  style="width:300px;"></el-input>
                <span style="color: red">*公众号appId</span>
            </el-form-item>
            <el-form-item label="appsecret" v-if="false">
                <el-input size="small" v-model="config.appsecret"  style="width:300px;"></el-input>
                <span style="color: red">*公众号appsecret</span>
            </el-form-item> -->
            <el-form-item label="">
                <el-button type="primary" @click="setConfig">确 定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<style>
   #allConfig .colorSet{
     display: flex;
   }
   #allConfig .colorSet>div{
     width: 60px;
     height: 30px;
     margin-right: 10px;
     background-color: #f5f5f5;
     margin-top: 5px;
     cursor: pointer;
     border: 2px solid #F5F5F5;
   }
   #allConfig .active{
     border: 2px solid mediumpurple !important;
   }
</style>
<script>
    import selecrImg from '@/components/selectImg'
    export default {
      components:{
        selecrImg
      },
        data(){
          return {
            config:{
				score_rebate:0,
                is_score_give:'0',
                score_name:'我的积分',
                appid:'',
                appsecret:'',
                colorIndex:1,
                qr_img:'',
                shop_address:'',
                is_order:'1',
                shop_logo:'',
                order_address:'', //可以下单的地址字符串
                delivery_address:'',//配送地区
                notice:'',//展铺公告
				order_number:'', //可下单次数
            },
            selectAddress:[],
            addressList:[],
          }
        },
        mounted(){
            document.title='全局配置'
            this.getConfig()
            this.getMemberAddress()
        },
        methods:{
          getMemberAddress(){
            this.$post('/pc/member/getAddress',{},res=>{
                let result = res.data
                if(result.code==200){
                   let address = result.data.member_address
                   let len = address.length - 1
                   address = address.slice(0,len)
                   this.addressList = address.split(',')

                }
            })

          },
          getLogo(data){
            this.config.shop_logo = data.url
          },
          getImg(data){

             this.config.qr_img = data.url
          },
          selectColorIndex(i){
              this.config.colorIndex = i
          },
            setConfig(){
                let _this=this
                if(this.selectAddress.length!=0){
                  this.config.order_address = this.selectAddress.join(',')
                }
				if(this.config.is_score_give==0){
					this.config.score_rebate = 0
				}
                this.$post('/pc/globle/setConfig',_this.config,function (res) {
                        let result =res.data
                      _this.$message.success(result.msg)
                })
            },
            getConfig(){
                let _this = this
                this.$post('/pc/globle/getConfig',{},function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.config.score_name = result.data.score_name
                        _this.config.appid = result.data.appid
                        _this.config.appsecret = result.data.appsecret
                        _this.config.colorIndex = result.data.colorIndex
                        _this.config.qr_img = result.data.qr_img
                        _this.config.shop_address = result.data.shop_address
                        _this.config.is_order = result.data.is_order
                        _this.config.shop_logo = result.data.shop_logo
						_this.config.order_number = result.data.order_number
                        if(result.data.order_address){
                           _this.selectAddress = result.data.order_address.split(',')
                        }
                        _this.config.delivery_address = result.data.delivery_address
                         _this.config.notice = result.data.notice
						_this.config.score_rebate = result.data.score_rebate
						if(_this.config.score_rebate){
							_this.config.is_score_give = '1'
						}else{
							_this.config.is_score_give = '0'
						}
                    }

                })
            }
        }
    }
</script>
