<template>
    <div>
        <el-button @click="selectImg" size="small">选择图片</el-button>
        <el-dialog

                title="图片列表"
                :append-to-body="true"
                :visible.sync="show_img_table"
                width="60%"
        >
            <div>
                <div style="float: right">
                    <el-button type="primary" size="mini" @click="show_uplode_img=true">上传图片</el-button>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div class="searchForm">
                <div>
                    类型： <el-input size="small" v-model="search_form.type" placeholder="请输入类型"  style="width:150px;"></el-input>
                </div>
                <div>
                    <el-button type="primary" size="mini" @click="search">检索</el-button>
                    <el-button size="mini" @click="clearSearch">重置/检索</el-button>
                </div>
            </div>
            <el-table  :data="imgList"   border  style="width: 100%;padding-bottom: 70px">
                <el-table-column   align="center"  label="图片">
                    <template slot-scope="scope"  >
                        <img :src="goodsImgUrl + scope.row.img_url" alt="" style="width: 50px;height: 50px" @click="look(scope.row)">
                    </template>
                </el-table-column>
                <el-table-column label="添加时间" width="180"  align="center" >
                          <template slot-scope="scope">
                             <div>
							    {{scope.row.add_time | formatTime}}
                             </div>
                          </template>
                </el-table-column>
                <el-table-column label="上传人"   align="center"  prop="uplode_person"></el-table-column>
                <el-table-column label="类型"   align="center"  prop="type"></el-table-column>
                <el-table-column  label="操作"  fixed="right"  align="center">
                    <template slot-scope="scope">
                        <el-button @click="confirmImg(scope.row)" type="text" size="small">选择</el-button>
                        <el-button @click="look(scope.row)" type="text" size="small">查看</el-button>
                        <el-button type="text" size="small" @click="del(scope)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page" style="position: relative;top: -70px">
                <el-pagination
                        background
                        @size-change="pageChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page_totle">
                </el-pagination>
            </div>
            <el-dialog
                    title="查看图片"
                    :visible.sync="show_big_img"
                    :append-to-body="true"
                    width="500px"
            >
                <div style="text-align: center;padding: 10px">
                    <img :src="goodsImgUrl + bigImg" alt="" style="max-width: 100%">
                </div>

            </el-dialog>
             <!-- :http-request="e=>handleUpload(e)" -->
            <el-dialog
                    title="上传图片"
                    :visible.sync="show_uplode_img"
                    :append-to-body="true"
                    width="500px"
            >
                <el-upload class="upload-demo"
                           name="file"
                           :data="imgData"
                           drag
                           :on-success="uplodeSuccess"
                           :action="action"
                           :multiple="false"
                           ref="newupload1"
                           :auto-upload="false"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>     </div>
                    <!-- <div slot="tip" style="color: red">上传的文件名称建议加上属于自己的前缀防止同名造成误删</div> -->
                </el-upload>
                <br>
                图片类型：  <el-input size="small" v-model="type" placeholder="请输入图片类型"  style="width:300px;"></el-input>
                <br>
                <br>
                <el-button type="primary" size="mini" @click="newSubmitForm()" class="yes-btn" icon="importDataBtnIcon">
                    点击上传
                </el-button>
            </el-dialog>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                show_img_table:false,
                type:'',
                search_form:{
                    type:'',
                },
                action:'',
                pageSize:10,
                page:1,
                page_totle:0,
                show_big_img:false,
                imgUrl:[],
                imgList:[

                ],
                scsParams:{},
                show_uplode_img:false,
                bigImg:'',
                imgData:{},
            }
        },
        mounted(){
            this.getImgList()
            this.action = this.$domain+'/scs/index/addGoodImg'
        },
        methods:{
            confirmImg(row){
                let data = {
                    url: row.img_url
                };
                this.show_img_table = false
                this.$emit('getImg',data);
            },
            selectImg(){
                this.show_img_table = true
            },
            search(){
              this.page=1
                this.getImgList()
            },
            clearSearch(){
                this.search_form.type=''
                this.page=1
                this.pageSize=10
                this.getImgList()
            },
            del(el){
                let _this=this
                if(confirm('确定删除')){
                    var index = el.$index
                    var cid=el.row.cid
                    this.$post('/scs/index/delGoodImg',{img_name:el.row.img_name,id:el.row.id},function (res) {
                        let result = res.data
                        if(result.code==200){
                            _this.imgList.splice(index,1)
                            _this.page_totle--
                            _this.$message.success(result.msg)
                        }
                    })
                }
            },
            pageChange(pageSize){
                this.page=1
                this.getImgList()
            },
            handleCurrentChange(page){
                this.page=page
                this.getImgList()
            },
            look(row){
                this.bigImg = row.img_url
                this.show_big_img = true
            },
            getImgList(){
                let _this=this
                _this.search_form.page = _this.page
                _this.search_form.pageSize = _this.pageSize
                this.$post('/pc/globle/getImgList',_this.search_form,function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.imgList = result.data.result
                        _this.page_totle=result.data.count
                    }
                })
            },
            handleUpload(e) {
               let fd = new FormData()// FormData 对象
                fd.append('file', e.file)// 文件对象
                fd.append('type', this.type)
                fd.append('cid', sessionStorage.getItem('cid'))
                fd.append('uplode_person', '管理员')
                this.$post('/scs/index/addGoodImg',fd,res=>{
                      let result = res.data
                      if(result.code==200){
                          this.show_uplode_img = false
                          this.$refs.newupload.clearFiles();
                          this.getImgList()
                      }
                })
            },
            newSubmitForm () {
              this.imgData.type = this.type
              this.imgData.cid = sessionStorage.getItem('cid')
              this.imgData.uplode_person = '管理员'
                this.$refs.newupload1.submit()
            },
            uplodeSuccess(e){
				console.log(11111)
            	if(e.code==200){
            		this.$refs.newupload1.clearFiles();
            		this.getImgList()
            	}else{
            		this.$message.error(e.msg)
            	}
              
            },
        },

    }
</script>
